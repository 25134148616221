<template>
    <div>
        <portal to="appBarNavigationTitle">
            {{ currentTitle }}
        </portal>
        <v-container fluid class="mt-3">
            <v-row fill-height>
                <v-col v-if="$hasRole('ROLE_TENANT_ADMIN')" cols="12" md="6" lg="4" class="pl-0">
                    <v-card height="100%" color="secondary">
                        <v-list color="transparent">
                            <v-subheader class="text-uppercase primary--text">{{ $t('companyGroup') }}</v-subheader>
                            <v-list-item-group color="primary">
                                <div v-for="item in arrSettings.tenant" :key="item.name">
                                    <SettingsListItem 
                                        :item="item"
                                        :key="item.name" />
                                </div>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col v-if="$hasRole('ROLE_TENANT_ADMIN')" cols="12" md="6" lg="4">
                    <v-card height="100%" color="secondary">
                        <v-list color="transparent">
                            <v-subheader class="text-uppercase primary--text">{{ $t('company') }}</v-subheader>
                            <v-list-item-group color="primary">
                                <div v-for="item in arrSettings.company" :key="item.name">
                                    <SettingsListItem 
                                        :item="item"
                                        :key="item.name" />
                                </div>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4" class="pr-0">
                    <v-card height="100%" color="secondary">
                        <v-list color="transparent">
                            <v-subheader class="text-uppercase primary--text">{{ $t('user') }}</v-subheader>
                            <v-list-item
                                v-if="currentUser && currentUser.fullName && currentUser.email"
                                two-line>
                                <v-list-item-avatar color="grey lighten-3 mr-8" :size="24">
                                    <span class="caption" v-text="currentUserInitials" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="currentUser.fullName"></v-list-item-title>
                                    <v-list-item-subtitle v-text="currentUser.email"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item-group color="primary">
                                <div v-for="item in arrSettings.user" :key="item.name">
                                    <SettingsListItem 
                                        :item="item"
                                        :key="item.name" />
                                </div>
                            </v-list-item-group>
                            <v-spacer></v-spacer>
                            <v-divider></v-divider>
                            <v-list-item-group color="primary">
                                <v-list-item disabled>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="'LEA Version ' + $leaVersion"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { getDetails } from '@/utils/auth';
import activeAppsMixin from '@/mixins/activeAppsMixin';
import titleMixin from '@/mixins/titleMixin';
import ScrollHeaderMixin from '@/components/vvt/ScrollHeaderMixin';
import SettingsListItem from './SettingsListItem.vue';

export default {
    name: 'Settings',
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    components: {
        SettingsListItem
    },
    mixins: [activeAppsMixin, titleMixin, ScrollHeaderMixin],
    title() {
        return this.currentTitle;
    },
    data() {
        return {
            disabledTooltip: false,
        };
    },
    computed: {
        currentUser() {
            return getDetails();
        },
        currentUserInitials() {
            if (!this.currentUser) {
                return '';
            }
            const firstName = this.currentUser.firstName;
            const lastName = this.currentUser.lastName;
            return this.currentUser && firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : ''
        },
        currentTitle() {
            return this.$t('navigation_items.settings');
        },
        arrSettings() {
            let arrSettings = [
                {
                    name: 'UserSettingsChangeLanguage',
                    route: { name: 'SettingsChangeLanguage' },
                    icon: 'language',
                    label: this.$t('settings.changeLanguage.buttonLabel'),
                    disabled: this.$ssoMode,
                    role: null,
                    feature: null,
                    sort: 300,
                    group: 'user'
                },
                {
                    name: 'UserSettingsChangePassword',
                    route: { name: 'SettingsChangePassword' },
                    icon: 'security',
                    label: this.$t('settings.changePassword.buttonLabel'),
                    disabled: this.$ssoMode,
                    role: null,
                    feature: null,
                    sort: 310,
                    group: 'user'
                },
                {
                    name: 'CompanySettingsCompanyList',
                    route: { name: 'SettingsCompanyList' },
                    icon: 'mdi-home-modern',
                    label: this.$t('settings.companies.buttonLabel'),
                    disabled: false,
                    role: 'ROLE_TENANT_ADMIN',
                    feature: null,
                    sort: 250,
                    group: 'company'
                },
                {
                    name: 'CompanySettingsCompanyDepartments',
                    route: { name: 'SettingsCompanyDepartments' },
                    icon: 'js-icon-department',
                    label: this.$t('settings.department.buttonLabel'),
                    disabled: false,
                    role: 'ROLE_TENANT_ADMIN',
                    feature: null,
                    sort: 260,
                    group: 'company'
                },
                {
                    name: 'CompanySettingsStaff',
                    route: { name: 'SettingsStaff' },
                    icon: 'mdi-account-supervisor',
                    label: this.$t('settings.contact.buttonLabel'),
                    disabled: this.$ssoMode,
                    role: 'ROLE_TENANT_ADMIN',
                    feature: null,
                    sort: 280,
                    group: 'company'
                }
            ];
            if (this.hasVvtApp) {
                arrSettings = [
                    ...arrSettings,
                    ...[
                        {
                            name: 'TenantSettingsCompanyGroup',
                            route: { name: 'SettingsCompanyGroup' },
                            icon: 'mdi-google-circles-extended',
                            label: this.$t('settings.companyGroup.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 100,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsDomains',
                            route: { name: 'SettingsDomains' },
                            icon: 'mdi-select-group',
                            label: this.$t('settings.domain.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 110,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsVendor',
                            route: { name: 'SettingsVendor' },
                            icon: 'mdi-warehouse',
                            label: this.$t('settings.vendor.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 120,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsVvtGroupTemplatesList',
                            route: { name: 'SettingsVvtGroupTemplatesList' },
                            icon: 'mdi-file-star-outline',
                            label: this.$tc('companyGroupTemplates',2),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 130,
                            group: 'tenant'
                        },
                        {
                            name: 'UserSettingsRecordFilter',
                            route: { name: 'SettingsRecordFilter', params: { filterType: 'user' }},
                            icon: 'mdi-filter-outline',
                            label: this.$t('settings.recordFilter.buttonLabel'),
                            disabled: false,
                            role: null,
                            feature: null,
                            sort: 320,
                            group: 'user'
                        },
                        {
                            name: 'TenantSettingsRecordFilter',
                            route: { name: 'SettingsRecordFilter', params: { filterType: 'group' }},
                            icon: 'mdi-filter-outline',
                            label: this.$t('settings.recordFilter.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 140,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsPersonalData',
                            route: { name: 'SettingsPersonalData' },
                            icon: 'mdi-clipboard-account-outline',
                            label: this.$t('settings.personalData.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 150,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsPersonalDataCategories',
                            route: { name: 'SettingsPersonalDataCategories' },
                            icon: 'js-icon-connection',
                            label: this.$t('settings.personalDataCategory.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 160,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsGroupsOfPerson',
                            route: { name: 'SettingsGroupsOfPerson' },
                            icon: 'mdi-account-group-outline',
                            label: this.$t('settings.groupsOfPersons.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 170,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsDeletionPractise',
                            route: { name: 'SettingsDeletionPractise' },
                            icon: 'mdi-delete-empty-outline',
                            label: this.$t('settings.deletionPractise.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 180,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsTools',
                            route: { name: 'SettingsTools' },
                            icon: 'laptop',
                            label: this.$t('settings.tool.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 190,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsSecurityControls',
                            route: { name: 'SettingsSecurityControls' },
                            icon: 'mdi-server-security',
                            label: this.$t('settings.securityControl.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 191,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsInternalRecipientCategories',
                            route: { name: 'SettingsInternalRecipientCategories' },
                            icon: 'js-icon-internalrecipient',
                            label: this.$t('settings.internalRecipientCategory.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 192,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsExternalRecipientCategories',
                            route: { name: 'SettingsExternalRecipientCategories' },
                            icon: 'mdi-store-outline',
                            label: this.$t('settings.externalRecipientCategory.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 193,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsAppropriateSafeguards',
                            route: { name: 'SettingsAppropriateSafeguards' },
                            icon: 'mdi-shield-lock-outline',
                            label: this.$t('settings.appropriateSafeguards.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 194,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsInternalKeywords',
                            route: { name: 'SettingsInternalKeywords' },
                            icon: 'mdi-bookmark-outline',
                            label: this.$t('settings.keyword.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 195,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsCompanyTom',
                            route: { name: 'SettingsCompanyTom' },
                            icon: 'mdi-security',
                            label: this.$t('settings.companyTom.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 270,
                            group: 'company'
                        },
                        {
                            name: 'TenantSettingsCompanyGroupNotification',
                            route: { name: 'SettingsCompanyGroupNotification' },
                            icon: 'mdi-email-edit-outline',
                            label: this.$t('settings.companyGroupNotification.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 196,
                            group: 'tenant'
                        },
                        {
                            name: 'TenantSettingsCompanyGroupHelp',
                            route: { name: 'SettingsCompanyGroupHelp' },
                            icon: 'mdi-lightbulb-on-outline',
                            label: this.$t('settings.companyGroupHelp.buttonLabel'),
                            disabled: false,
                            role: 'ROLE_TENANT_ADMIN',
                            feature: null,
                            sort: 197,
                            group: 'tenant'
                        },
                    ],
                ];
            }
            arrSettings = arrSettings.filter(x => {
                if (!x.disabled && (!x.role || this.$hasRole(x.role))) {
                    return x;
                }
            })
            let arrSettingsUser = arrSettings.filter(x => x.group === 'user').sort((a, b) => (a.sort - b.sort));
            let arrSettingsCompany = arrSettings.filter(x => x.group === 'company').sort((a, b) => (a.sort - b.sort));
            let arrSettingsTenant = arrSettings.filter(x => x.group === 'tenant').sort((a, b) => (a.sort - b.sort));
            return {
                user: arrSettingsUser,
                company: arrSettingsCompany,
                tenant: arrSettingsTenant
            };
        }
    },
    created() {
        this.initScrollHeader();
    },
};
</script>
