<template>
  <v-tooltip :disabled="!item.feature || hasFeature(item.feature)" bottom color="primary">
    <template v-slot:activator="{ on }">
      <v-list-item
          :inactive="item.feature && !hasFeature(item.feature)"
          :ripple="!item.feature || hasFeature(item.feature)"
          v-on="on"
          :to="item.route">
          <v-list-item-icon>
              <v-icon v-text="item.icon" :class="{'grey--text': item.feature && !hasFeature(item.feature)}" />
          </v-list-item-icon>
          <v-list-item-content>
              <v-list-item-title v-text="item.label" :class="{'grey--text': item.feature && !hasFeature(item.feature)}" />
          </v-list-item-content>
      </v-list-item>
    </template>
    <span v-t="'missingLeaFeature'" />
  </v-tooltip>
</template>

<script>
import { hasFeature } from '@/utils/auth';

export default {
  props: {
    item: {
      type: Object,
      default() {
        return {
            route: null,
            icon: null,
            label: null,
            disabled: false,
            role: 'ROLE_USER',
            feature: null,
            sort: null,
            group: null
        };
      }
    }
  },
  methods: {
      hasFeature(strFeature) {
          return hasFeature(strFeature);
      },
  },
}
</script>